<template>
	<app-modal :modal="conference" @closeModal="closeConferenceModal">
		<template slot="title"
			>{{ conference.id ? "Edit" : "New" }} Conference Invitation{{
				conference.invitees.length > 1 ? "s" : ""
			}}</template
		>

		<form
			v-on:submit.prevent="saveConference"
			ref="myForm"
			style="margin-top: -40px"
			class="-needs-validation -was-validated"
			:class="{ 'was-validated': errors.items.length > 0 && formSubmitted }"
		>
			<pre>{{ errors.items.length }}</pre>

			<pre>conference : {{ conference }}</pre>
			<table v-if="conference && conference.invitees">
				<tr>
					<td>
						<button
							v-if="conference.invitees.length < 6"
							type="button"
							class="btn btn-success mt-0"
							v-on:click.prevent="inviteMore"
						>
							<i class="material-icons"></i>
							Invite More
						</button>
					</td>
					<td></td>
					<td></td>
					<td style="text-align: center;"><small>Send Invite</small></td>
					<td></td>
				</tr>

				<template v-for="(invitee, i) in conference.invitees">
					<conference-modal-tr
						:invitee="invitee"
						:i="i"
						:conference_id="conference.id ? conference.id : false"
						:conference_invitees_length="conference_invitees_length"
						:key="'conference-modal-tr-invitee-' + i"
						@removeInvitee="removeInvitee(invitee)"
					/>
				</template>
			</table>

			<!--			<div style="display: flex; justify-content: flex-end;">-->
			<!--				-->
			<!--			</div>-->
		</form>

		<template slot="footer">
			<button
				type="button"
				class="btn btn-secondary"
				data-dismiss="modal"
				v-on:click.prevent="closeConferenceModal()"
			>
				Close
			</button>
			<button
				:disabled="buttonDisabled"
				type="button"
				class="btn btn-primary btn-lg"
				v-on:click.prevent="triggerSubmit"
			>
				Save & Send
				{{ conference.invitees.length > 1 ? conference.invitees.length : "" }}
				Invitation{{ conference.invitees.length > 1 ? "s" : "" }}
			</button>
		</template>
	</app-modal>
</template>

<script>
import { mapActions } from "vuex";

import ConferenceModalTr from "./ConferenceModalTr.vue";

export default {
	props: ["conference"],

	components: {
		ConferenceModalTr
	},

	data() {
		return {
			// form: {
			// 	name: null,
			// 	email: null,
			// },
			formSubmitted: false
		};
	},

	watch: {
		"conference.show": {
			handler: function(newVal) {
				if (!newVal) {
					this.form = {
						name: null,
						email: null,
						sms: null
					};
				}
			}
		}
	},

	computed: {
		conference_invitees_length() {
			let f = this.conference.invitees.filter(row => {
				return !row.delete;
			});
			return f.length;
		},

		buttonDisabled() {
			let r = false;

			this.conference.invitees.forEach(invitee => {
				if (!invitee.send_email && !invitee.send_sms) {
					console.log("!invitee.send_email && !invitee.send_sms");
					r = true;
				} else if (invitee.send_email && !invitee.email) {
					console.log("invitee.send_email && !invitee.email");
					r = true;
				} else if (invitee.send_sms && !invitee.sms) {
					console.log("invitee.send_sms && !invitee.sms");
					r = true;
				} else if (!invitee.name) {
					console.log("!invitee.name");
					r = true;
				}
			});

			return r;
		}
	},

	methods: {
		...mapActions(["ajax", "globalAlert"]),

		inviteMore() {
			this.$emit("inviteMore");
		},
		removeInvitee(invitee) {
			const sure = confirm("This participant will be removed.");
			if (sure) {
				this.$emit("removeInvitee", invitee);
			}
		},
		closeConferenceModal() {
			this.$emit("closeConferenceModal");
		},
		triggerSubmit() {
			this.formSubmitted = true;

			//this.$validator.validateAll().then(result => {
			//if (result) {
			// eslint-disable-next-line
			//alert('Form Submitted!');

			//var tis = this;

			this.ajax({
				invalid: true, // show server-side validation errors
				url: "/conference/request_conference",
				data: this.conference,
				success: json => {
					if (json.validation_errors) {
						//return false;
					} else if (json.error) {
						//return false;
					} else {
						this.$emit("refreshConferences");

						//this.refreshConferences();
					}
				}
			});

			//return;
			//} // if

			// this.globalAlert({
			// 	title: "Form Errors",
			// 	body: "Please correct errors."
			// });
			//}); // validateAll
		}
	},

	mounted() {
		//alert('mounted');
	}
};
</script>

<style lang="scss">
.conference_invitee:first-child {
	.remove_invitee {
		display: none;
	}
}
</style>
