<template>
	<div class="container-fluid">
		<div class="row justify-content-between mb-4">
			<div class="col-sm">
				<h1>Initiated Conference Requests</h1>
				<p class="lead text-muted">Invite others to a video conference call.</p>
			</div>
			<div class="col-auto mr-auto">
				<a
					class="btn btn-lg btn-success"
					v-on:click.prevent="newInvitation()"
					href
				>
					<i class="material-icons">&#xE145;</i>Add New
				</a>
			</div>
		</div>

		<div class="table-responsive">
			<table class="table table-borderless">
				<thead>
					<tr>
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">SMS</th>
						<!--						<th scope="col">Time</th>-->
						<th scope="col"></th>
					</tr>
				</thead>
				<tbody>
					<template v-for="n in rows">
						<tr :key="n.id" style="border-top: 1px solid rgba(0, 0, 0, 0.12);">
							<td colspan="3">
								<h3 class="mb-0 text-secondary">
									{{ n.request_sent | moment("dddd, MMMM Do YYYY, h:mm a") }}
								</h3>
							</td>
							<td align="right" class="text-right">
								<a
									href
									class="btn ml-1 btn-outline-secondary"
									@click.stop.prevent="editConference(n)"
									>Edit</a
								>
								<a
									href
									class="btn btn-secondary ml-1"
									@click.stop.prevent="resendRequest(n.id)"
									>Resend All</a
								>

								<a
									href
									class="btn btn-danger ml-1"
									v-if="n.id != activeRequestId"
									@click.stop.prevent="expireThisRequest(n.id)"
									>Expire</a
								>

								<a
									href
									class="btn btn-primary ml-1"
									v-if="n.id != activeRequestId"
									@click.stop.prevent="connectToRequest(n.id)"
									>Join</a
								>

								<a
									href
									class="btn btn-success ml-1"
									v-else
									@click.stop.prevent="false"
									>On Call</a
								>
							</td>
						</tr>

						<tr v-for="(invitee, i) in n.invitees" :key="n.id + '-foo-' + i">
							<th>{{ i + 1 }}. &nbsp;&nbsp;{{ invitee.name }}</th>
							<td>{{ invitee.email }}</td>
							<td colspan="2">{{ invitee.sms }}</td>

							<!--							<td align="right" class="text-right">-->
							<!--								<a-->
							<!--									href-->
							<!--									class="btn btn-secondary ml-1"-->
							<!--									@click.stop.prevent="resendRequest(n.id)"-->
							<!--									>Resend</a-->
							<!--								>-->

							<!--								<a-->
							<!--									href-->
							<!--									class="btn btn-danger ml-1"-->
							<!--									v-if="n.id != activeRequestId"-->
							<!--									@click.stop.prevent="expireThisRequest(n.id)"-->
							<!--									>Expire</a-->
							<!--								>-->

							<!--								<a-->
							<!--									href-->
							<!--									class="btn btn-primary ml-1"-->
							<!--									v-if="n.id != activeRequestId"-->
							<!--									@click.stop.prevent="connectToRequest(n.id)"-->
							<!--									>Join</a-->
							<!--								>-->

							<!--								<a-->
							<!--									href-->
							<!--									class="btn btn-success ml-1"-->
							<!--									v-else-->
							<!--									@click.stop.prevent="false"-->
							<!--									>On Call</a-->
							<!--								>-->
							<!--							</td>-->
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<conference-modal
			:conference="selectedRow"
			@refreshConferences="refresh"
			@closeConferenceModal="closeConferenceModal()"
			@inviteMore="inviteMore()"
			@removeInvitee="removeInvitee"
		></conference-modal>

		<!-- <app-modal :modal="removeFacilityModal" data-v-on:close-modal="closeModal">
      <template slot="title">Confirm Removal Hospital</template>

      <form>
        <p>Are you sure you want to permanently remove this hospital?</p>
      </form>

      <template slot="footer">
        <button
          type="button"
          class="btn btn-outline-secondary"
          data-dismiss="modal"
          v-on:click.prevent="removeFacilityModal.show = false"
        >Never mind</button>
        <button
          type="button"
          class="btn btn-danger"
          v-on:click.prevent="removeHospital(removeFacilityModal.id, true)"
        >Remove</button>
      </template>
    </app-modal>-->

		<pre>rows: {{ rows }}</pre>
	</div>
</template>

<script>
import ConferenceModal from "./ConferenceModal.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
	data() {
		return {
			rows: [],
			options: {
				states: []
			},
			selectedRow: {
				id: 568,
				show: false,
				close_method: function() {
					console.log("selectedRow.close_method");
				},
				invitees: []

				//utilized: 'always'
			}
		};
	},

	watch: {
		activeRequestId: function(newVal, oldVal) {
			if (!newVal) {
				this.expireThisRequest(oldVal);
			}
		}
	},

	methods: {
		...mapActions(["ajax", "toggleFacilityModal"]),
		...mapMutations(["updateActiveRequestId", "updateActivePendingId"]),

		// eslint-disable-next-line no-unused-vars
		editConference(conference) {
			this.$set(this.selectedRow, "id", conference.id);
			this.$set(this.selectedRow, "show", true);

			//let invitees = conference.invitees;

			//console.log("conference.invitees", conference.invitees);

			// eslint-disable-next-line no-unused-vars
			let invitees = conference.invitees.map(inv => {
				inv.send_email = !!inv.email;
				inv.send_sms = !!inv.sms;
				return inv;
			});

			this.$set(
				this.selectedRow,
				"invitees",
				JSON.parse(JSON.stringify(invitees))
			);
		},

		inviteMore() {
			this.selectedRow.invitees.push({
				name: null,
				email: null,
				sms: null,
				send_email: true,
				send_sms: false,
				resend: false
			});
		},

		removeInvitee(invitee) {
			//alert("invitee.id: " + invitee.id);

			// editing?
			if (invitee.id) {
				this.$set(invitee, "delete", true);
			}
			// this is a new array of invitees so just remove it
			else {
				this.selectedRow.invitees.splice(
					this.selectedRow.invitees.indexOf(invitee),
					1
				);
			}
		},

		connectToRequest(id) {
			this.updateActiveRequestId(id);
			this.updateActivePendingId(false);
		},

		closeConferenceModal() {
			this.$set(this.selectedRow, "id", null);
			this.$set(this.selectedRow, "show", false);
		},

		newInvitation() {
			this.$set(this.selectedRow, "id", null);
			this.$set(this.selectedRow, "show", true);
			this.$set(this.selectedRow, "invitees", [
				{
					name: null,
					email: null,
					sms: null,
					send_email: true,
					send_sms: false,
					resend: false
				}
			]);

			//this.toggleFacilityModal(true);
		},

		refresh() {
			this.selectedRow.show = false;
			this.ajax({
				url: "/conference/request_list",
				data: {},
				success: json => {
					this.rows = json.requests;
					//this.options = json.options;
				}
			});
		},

		resendRequest(id) {
			this.ajax({
				url: "/conference/request_resend_notification",
				data: { request_id: id },
				success: () => {
					//this.rows = json.requests;
					//this.options = json.options;
					alert("Invitation was resent.");
				}
			});
		},

		expireThisRequest(id) {
			var sure = confirm(
				"You are about to cancel this request and delete this conference."
			);

			if (sure) {
				this.ajax({
					url: "/conference/request_clear",
					data: { request_id: id },
					success: json => {
						//this.rows = json.requests;
						//this.options = json.options;
						if (json.success) {
							//alert("Request has been expired.");
							this.refresh();
						}
					}
				});
			}
		}
	},

	computed: {
		...mapState(["activeRequestId"])
	},

	mounted() {
		this.refresh();
	},

	components: {
		ConferenceModal
	}
};
</script>

<style lang="scss"></style>
