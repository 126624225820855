<template>
	<tr class="conference_invitee" :class="{ delete_invitee: invitee.delete }">
		<td>
			<div class="form-group mb-0">
				<label style="margin-bottom: 16px;">Name {{ invitee.delete }}</label>

				<input
					type="text"
					name="name"
					placeholder="First & Last Name"
					v-model="invitee.name"
					class="form-control"
					required
					v-validate="{ required: true }"
				/>

				<div class="invalid-feedback" v-show="errors.has('name')">
					{{ errors.first("name") }}
				</div>
			</div>
		</td>

		<td>
			<div class="form-group mb-0">
				<!--							<label>Email</label>-->

				<div class="btn-group-toggle" data-toggle="buttons">
					<label
						:data-for="'send_email_toggle' + i + '-' + conference_id"
						class="btn btn-sm"
						:class="{
							'text-light amber darken-3': invitee.send_email,
							'btn-outline-secondary': !invitee.send_email
						}"
					>
						<input
							:id="'send_email_toggle' + i + '-' + conference_id"
							type="checkbox"
							v-model="invitee.send_email"
							:value="1"
						/>
						<i v-if="invitee.send_email" class="material-icons -lime-text"
							>check</i
						>
						<i v-else class="material-icons plus">close</i>Via Email
					</label>
				</div>

				<input
					:disabled="!invitee.send_email"
					v-model="invitee.email"
					type="email"
					id="facility_name"
					name="email"
					placeholder="Email Address"
					class="form-control  -is-invalid mt-1"
					v-validate="{ required: invitee.send_email }"
					:required="invitee.send_email"
				/>

				<div class="invalid-feedback" v-show="errors.has('email')">
					{{ errors.first("email") }}
				</div>
			</div>
		</td>

		<td>
			<div class="form-group mb-0">
				<!--							<label>Text</label>-->

				<div class="btn-group-toggle" data-toggle="buttons">
					<label
						:for="'send_sms_toggle' + i + '-' + conference_id"
						class="btn btn-sm"
						:class="{
							'text-light amber darken-3': invitee.send_sms,
							'btn-outline-secondary': !invitee.send_sms
						}"
					>
						<input
							:id="'send_sms_toggle' + i + '-' + conference_id"
							type="checkbox"
							v-model="invitee.send_sms"
							:value="1"
						/>
						<i v-if="invitee.send_sms" class="material-icons -lime-text"
							>check</i
						>
						<i v-else class="material-icons plus">close</i>Via SMS
					</label>
				</div>

				<input
					v-model="invitee.sms"
					:disabled="!invitee.send_sms"
					type="tel"
					id="sms"
					name="sms"
					placeholder="(123)123-1234"
					class="form-control -is-invalid mt-1"
					v-validate="{ required: invitee.send_sms }"
					:required="invitee.send_sms"
				/>

				<div class="invalid-feedback" v-show="errors.has('sms')">
					{{ errors.first("sms") }}
				</div>
			</div>
		</td>
		<td style="text-align: center;">
			<div
				class="custom-control custom-checkbox"
				style="position: relative; top: -19px; display: inline-block; left: 5px"
			>
				<input
					v-model="invitee.resend"
					type="checkbox"
					class="custom-control-input"
					:id="'resend-check-' + i"
					:disabled="disable_send"
				/>
				<label class="custom-control-label" :for="'resend-check-' + i"
					>&nbsp;</label
				>
			</div>
		</td>
		<td style="text-align: right;">
			<a
				v-show="conference_invitees_length > 1"
				href=""
				class="btn btn-sm btn-link remove_invitee"
				@click.prevent="removeInvitee(invitee)"
				><i class="material-icons red-text" style="opacity: 1; ">cancel</i></a
			>
		</td>
	</tr>
</template>

<script>
export default {
	name: "ConferenceModalTr.vue",
	props: ["invitee", "i", "conference_invitees_length", "conference_id"],
	data() {
		return {
			disable_send: false
		};
	},
	methods: {
		removeInvitee(invitee) {
			this.$emit("removeInvitee", invitee);
		}
	},
	watch: {
		"invitee.email": {
			handler() {
				this.disable_send = true;
				this.$set(this.invitee, "resend", true);
			}
		},
		invitee: {
			handler(newVal) {
				if (newVal.email) {
					//this.$set(this.invitee, "send_email", true);
				}
				if (newVal.sms) {
					//this.$set(this.invitee, "send_sms", true);
				}
			},
			deep: true,
			immediate: true
		},
		"invitee.sms": {
			handler() {
				this.disable_send = true;
				this.$set(this.invitee, "resend", true);
			}
		}
	}
};
</script>

<style scoped lang="scss">
.delete_invitee {
	display: none;
}
</style>
